import * as React from 'react';
import { MainMenuItem } from '../../../components/MainMenuItem';
import { AppContext, DisplayModeType, InterfaceModules } from '@brainysoft/lk-components';
import { useThemeStyles } from '../../../utils/useThemeStyles';
import { HEADER_INVERTED } from '../../../config';
import { useInterfaceModuleSetting } from '../../../utils/useInterfaceModuleSetting';
import { ProfileMenuContainer } from '../../../containers/ProfileMenu';
import { useTranslation } from '../../../utils/useTranslation';

const COMPLAIN_URL = 'https://kredito24.ru/#appeal';

export const Menu: React.FC = () => {
  const { state } = AppContext.useContainer();
  const { t } = useTranslation();
  const classes = useThemeStyles('Header');

  const mode = state.displayMode;
  const activePage = state.activePage;

  const invertedClass = HEADER_INVERTED ? 'inverted' : '';

  const contractsPageSettings = useInterfaceModuleSetting(InterfaceModules.CONTRACTS_PAGE) ?? { active: true };
  const paymentsPageSettings = useInterfaceModuleSetting(InterfaceModules.PAYMENTS_PAGE) ?? { active: true };
  const documentPageSettings = useInterfaceModuleSetting(InterfaceModules.DOCUMENTS_PAGE) ?? { active: true };
  const complainPageSettings = useInterfaceModuleSetting(InterfaceModules.COMPLAIN_PAGE) ?? { active: true };
  const [opened, setOpened] = React.useState(false);

  const toggleBurgerMenu = () => {
    setOpened(!opened);
  };

  return (
    <React.Fragment>
      {mode === DisplayModeType.DESKTOP && (
        <div className={`${classes.menuWrapper} ${invertedClass}`}>
          {!!contractsPageSettings?.active && (
            <MainMenuItem href={'/contracts'} itemText={t('header:contracts')} active={activePage === 'contracts'} />
          )}
          {!!paymentsPageSettings?.active && (
            <MainMenuItem href={'/payments'} itemText={t('header:payments')} active={activePage === 'payments'} />
          )}
          {!!documentPageSettings?.active && (
            <MainMenuItem href={'/documents'} itemText={t('header:docs')} active={activePage === 'documents'} />
          )}
          {!!complainPageSettings?.active && (
            <MainMenuItem href={COMPLAIN_URL} itemText={t('header:complain')} external={true} />
          )}
          <ProfileMenuContainer />
        </div>
      )}
      {mode !== DisplayModeType.DESKTOP && (
        <React.Fragment>
          <div className={classes.burgerMenuBtnWrapper} onClick={toggleBurgerMenu}>
            <div className={[classes.burgerMenuBtn, invertedClass, opened ? 'active' : null].join(' ')} />
          </div>
          <div className={[classes.offcanvasMenu, invertedClass, opened ? 'active' : null].join(' ')}>
            <div className={classes.closeMenuBtnWrapper} onClick={toggleBurgerMenu}>
              <span className={classes.closeMenuBtn} />
            </div>
            <div className={classes.items}>
              {!!contractsPageSettings?.active && (
                <MainMenuItem
                  href={'/contracts'}
                  itemText={t('header:contracts')}
                  offcanvas={true}
                  active={activePage === 'contracts'}
                />
              )}
              {!!paymentsPageSettings?.active && (
                <MainMenuItem
                  href={'/payments'}
                  itemText={t('header:payments')}
                  offcanvas={true}
                  active={activePage === 'payments'}
                />
              )}
              {!!documentPageSettings?.active && (
                <MainMenuItem
                  href={'/documents'}
                  itemText={t('header:docs')}
                  offcanvas={true}
                  active={activePage === 'documents'}
                />
              )}
              <ProfileMenuContainer />
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
