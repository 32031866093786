import * as React from 'react';
import { useThemeStyles } from '../../../utils/useThemeStyles';
import {
  cacheService as Storage,
  ContractT,
  RepaymentsAvailabilityDatesT,
  RepaymentsAvailabilityT,
  RepaymentType,
} from '@brainysoft/lk-components';
import { Button } from '../../../components/Button';
import { useTimer } from '../../../utils/useTimer';
import { useTranslation } from '../../../utils/useTranslation';
import { useRouter } from 'next/router';
import { useRepaymentAvailability, useRepaymentAvailabilityDates } from '../../../queries/useRepaymentAvalability';
import { Skeleton } from 'antd';
import Dayjs from 'dayjs';

interface IProps {
  contract: ContractT;
}

export const ProlongationWidget: React.FC<IProps> = (props) => {
  const classes = useThemeStyles('RepaymentWidget');
  const { t } = useTranslation();
  const router = useRouter();
  const { isExpired } = useTimer('repayment');

  const { contract } = props;

  const repaymentsAvailabilityQuery = useRepaymentAvailability(contract?.id);
  const repaymentsAvailability = repaymentsAvailabilityQuery.data || ({} as RepaymentsAvailabilityT);
  const repaymentsAvailabilityDatesQuery = useRepaymentAvailabilityDates(contract?.id);
  const repaymentsAvailabilityDates = repaymentsAvailabilityDatesQuery.data || ({} as RepaymentsAvailabilityDatesT);

  const repaymentType = RepaymentType.PROLONGATION;

  const onClick = async () => {
    await Storage.setItem(`repayment:${repaymentType}:contractId`, contract?.id);
    await router.push(`/repayment/${repaymentType}/init`);
  };

  if (repaymentsAvailabilityQuery.isLoading || repaymentsAvailabilityDatesQuery.isLoading)
    return (
      <div className={`${classes.repaymentWidgetWrapper} ${repaymentType}`}>
        <Skeleton className={classes.skeleton} active={true} title={false} paragraph={{ rows: 2, width: '80%' }} />
      </div>
    );

  const repaymentDates = repaymentsAvailabilityDates[repaymentType];

  if (!repaymentsAvailability[repaymentType] && repaymentDates?.isVisible && repaymentDates?.availability?.start) {
    const date = Dayjs(repaymentDates?.availability?.start).format('DD.MM.YYYYг.');
    return (
      <div className={`${classes.repaymentWidgetWrapper} ${repaymentType}`}>
        <div className={classes.repaymentWidgetTitle}>{t(`widgets:repaymentWidget.${repaymentType}.title`)}</div>
        <div className={classes.repaymentInfo}>
          Кнопка &quot;Продлить&quot; будет доступна c {date}. Если вы попали в трудную ситуацию и чувствуете, что не
          сможете вовремя погасить заём, рекомендуем вам воспользоваться данной услугой заблаговременно.
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            type={'primary'}
            shape='round'
            size='small'
            color={'white'}
            className={classes.button}
            disabled={true}
            onClick={onClick}
          >
            {t(`widgets:repaymentWidget.${repaymentType}.makePayment`)}
          </Button>
        </div>
      </div>
    );
  }

  if (repaymentsAvailability[repaymentType]) {
    return (
      <div className={`${classes.repaymentWidgetWrapper} ${repaymentType}`}>
        <div className={classes.repaymentWidgetTitle}>{t(`widgets:repaymentWidget.${repaymentType}.title`)}</div>

        {!isExpired && <div className={classes.repaymentInfo}>{t('widgets:repaymentWidget.repaymentInfo')}</div>}

        {isExpired && (
          <>
            <div className={classes.repaymentInfo}>
              Если вы попали в трудную ситуацию и чувствуете, что не сможете вовремя погасить заём, рекомендуем вам
              воспользоваться услугой продления, чтобы ваша кредитная история не была испорчена. За один заём можно
              оформить не более пяти пролонгаций.
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                type={'primary'}
                shape='round'
                size='small'
                color={'white'}
                className={classes.button}
                disabled={!isExpired}
                onClick={onClick}
              >
                {t(`widgets:repaymentWidget.${repaymentType}.makePayment`)}
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }

  return <></>;
};
