const colors = require('./theme_colors');
const variables = require('./theme_variables');

module.exports = () => {
  return {
    '@primary-color': colors.primary,
    '@error-color': colors.red,
    '@green-base': colors.green,
    '@highlight-color': colors.highlightColor,
    '@font-family': variables.fontFamily,
    '@font-size-base': variables.fontSizeBase,
    '@font-size-lg': variables.fontSizeLarge,
    '@font-size-sm': variables.fontSizeSmall,
    '@border-color-base': colors.borderColor,
    '@border-radius-base': variables.generalBorderRadius,
    '@input-hover-border-color': variables.inputBorderColor,
    '@input-number-handler-hover-bg': colors.primary,
    '@disabled-bg': colors.disabledBackground,
  };
};
