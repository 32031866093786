import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { merge } from 'lodash';

import { css as original } from '@custom/defaults/theme/Components/Logo/styles';

export const css = merge(original, {
  logoWrapper: {
    maxWidth: '13rem',
    marginRight: '2rem',
  },
  [`@media (max-width: 1200px)`]: {
    logoWrapper: {
      maxWidth: '10rem',
      marginRight: '1.5rem',
    },
  },
});

export default css;
