import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { green, primary, white } from '../../../colors';
import { colors } from '../../Styles';
import Color from 'color';

import { css as original } from '@custom/defaults/theme/Components/Button/styles';
import { merge } from 'lodash';

const { orange, primary2, orange3 } = colors;

/* tslint:disable object-literal-key-quotes */
/* tslint:disable object-literal-sort-keys */

export const css = merge(original, {
  button: {
    lineHeight: '1 !important',
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none',
    '&.ant-btn-sm': {
      fontSize: '.875em !important',
    },
    '&.ant-btn-round.ant-btn-lg': {
      padding: '2px 20px 4px',
      '& .anticon': {
        position: 'relative',
        top: 2,
      },
    },
    '&.ant-btn-primary:not([disabled])': {
      background: `linear-gradient(180deg, ${Color(orange3)
        .darken(0.05)
        .rgb()
        .string()} 0%, ${orange} 100%) !important`,
      borderColor: 'transparent',
      position: 'relative',
      overflow: 'hidden',
      border: 0,
      '& > *': {
        position: 'relative',
        zIndex: 1,
      },
      '&:hover': {
        background: `linear-gradient(180deg, ${orange3} 0%, ${Color(orange)
          .lighten(0.1)
          .rgb()
          .string()} 100%) !important`,
      },
      '&:before': {
        content: 'none',
      },
    },
    '&.green': {
      background: `${green} !important`,
      borderColor: 'transparent',
      position: 'relative',
      overflow: 'hidden',
      border: 0,
    },
    '&.secondary': {
      background: `linear-gradient(180deg, ${primary2} 0%, ${primary} 100%) !important`,
      color: `${white} !important`,
      borderColor: 'transparent',
      position: 'relative',
      overflow: 'hidden',
      border: 0,
    },
    '&.wide': {
      width: '100%',
      justifyContent: 'center',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    button: {
      fontSize: '0.875rem',
      padding: ['0.3125rem', '1.25rem'],
      '&.ant-btn-lg': {
        fontSize: '1rem',
      },
    },
  },
});

export default css;
