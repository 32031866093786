import printFormsRu from './translations/ru/printForms.json';
import headerRu from './translations/ru/header.json';
import widgetsRu from './translations/ru/widgets.json';
import authRu from './translations/ru/auth.json';
import motivationLabelRu from './translations/ru/motivationLabel.json';
import otpRu from './translations/ru/otp.json';
import contractRu from './translations/ru/contract.json';
import activeRu from './translations/ru/active.json';
import denyRu from './translations/ru/deny.json';

export const translations = {
  ru: {
    printForms: printFormsRu,
    header: headerRu,
    widgets: widgetsRu,
    auth: authRu,
    motivationLabel: motivationLabelRu,
    otp: otpRu,
    contract: contractRu,
    active: activeRu,
    deny: denyRu,
  },
};
