import * as React from 'react';
import { Collapse, Table } from 'antd';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';

export const PaymentsDisclaimer: React.FC = () => {
  const classes = useThemeStyles('Collapse');
  const tableClasses = useThemeStyles('PaymentsTable');

  return (
    <Collapse className={`${classes.collapse} mb2`} expandIconPosition={'end'}>
      <Collapse.Panel header={<h4 style={{ marginBottom: 0 }}>Способы погашения</h4>} key='disclaimer'>
        <div className={[classes.scheduleTable, 'js-scheduleTable'].join(' ')}>
          <table className={tableClasses.compactTable}>
            <thead className={tableClasses.compactTableHeader}>
              <tr>
                <th>Способ оплаты</th>
                <th>Партнер</th>
                <th>Комиссия</th>
                <th>Как</th>
              </tr>
            </thead>
            <tbody className={tableClasses.compactTableBody}>
              <tr>
                <td rowSpan={2}>
                  <b>Терминалы</b>
                </td>
                <td>Элекснет</td>
                <td>0%</td>
                <td>
                  Адреса терминалов{' '}
                  <a href='https://elecsnet.ru/terminals/' rel={'noopener noreferrer'} target={'_blank'}>
                    по ссылке
                  </a>
                </td>
              </tr>
              <tr>
                <td>МКБ</td>
                <td>0%</td>
                <td>
                  Адреса терминалов{' '}
                  <a href='https://mkb.ru/about/address/terminal' rel={'noopener noreferrer'} target={'_blank'}>
                    по ссылке
                  </a>
                </td>
              </tr>

              <tr>
                <td>
                  <b>Банковский перевод</b>
                </td>
                <td>Другие банки</td>
                <td>Уточните в своем банке</td>
                <td>
                  Переведите необходимую сумму на реквизиты Кредито24. Наличие комиссии и сроки перевода уточните в
                  своем банке.
                </td>
              </tr>
              <tr>
                <td rowSpan={2}>
                  <b>Оплата на сайте</b>
                </td>
                <td>В личном кабинете</td>
                <td>0-6%</td>
                <td>
                  Перейдите для оплаты в <a href='https://lk.kredito24.ru/auth'>личном кабинете</a>
                </td>
              </tr>
              <tr>
                <td>Запрос ссылки на оплату</td>
                <td>0-6%</td>
                <td>
                  Запросите ссылку в службе поддержки клиентов по телефону{' '}
                  <a href='tel:+74952259063' style={{ whiteSpace: 'nowrap' }}>
                    <b>+7 495 225-90-63</b>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};
