import * as defaultComponents from '../default';
import { ChallengeApproval } from './ChallengeApproval';
import { Menu } from './Menu';
import { ProfileMenu } from './ProfileMenu';
import { ProlongationChooseAmount } from './Prolongation/ProlongationChooseAmount';
import { ProlongationSignDocument } from './Prolongation/ProlongationSignDocument';
import { SurveyFormSmokers } from './SurveyFormSmokers/SurveyFormSmokers1';
import { PaymentsDisclaimer } from './PaymentsDisclaimer';
import { LoanAppCreateWidget } from './Widgets/LoanAppCreateWidget';
import { PaymentsTable } from './PaymentsTable';
import { InteractionSignOffer } from './ActiveCreditView/Statuses/InteractionSignOffer';
import { ProlongationWidget } from './Repayments/ProlongationWidget';
import { Denied } from './Widgets/Denied';
import { AuthorizedContentWrapperComponent } from './AuthorizedContentWrapper';

export default {
  ...defaultComponents,
  ChallengeApproval,
  Menu,
  ProfileMenu,
  ProlongationChooseAmount,
  ProlongationSignDocument,
  SurveyFormSmokers,
  PaymentsDisclaimer,
  LoanAppCreateWidget,
  PaymentsTable,
  InteractionSignOffer,
  ProlongationWidget,
  Denied,
  AuthorizedContentWrapperComponent,
};
