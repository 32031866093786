import { merge } from 'lodash';
import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { greyText, primary, white } from '@custom/monedo/colors';

import { css as original } from '@custom/defaults/theme/Components/HeaderPhone/styles';

export const css = merge(original, {
  phoneWrap: {
    marginLeft: '1rem',
    flexShrink: 1,
    '&.inside': {
      '& $phoneNumber': {
        fontSize: '1.25rem',
      },
    },
  },
  [`@media (max-width: 1200px)`]: {
    phoneWrap: {
      '&.inside': {
        '& $phoneNumber': {
          fontSize: '1.125rem',
        },
      },
    },
  },
});

export default css;
