import { useEffect, useState } from 'react';

export const useDelay = (fn, delay, expression = true) => {
  const [id, setId] = useState(0);

  useEffect(() => {
    if (id > 0) {
      window.clearTimeout(id);
      setId(0);
    }
    if (expression)
      setId(
        window.setTimeout(() => {
          fn();
          setId(0);
        }, delay)
      );

    return () => {
      if (id > 0) {
        window.clearTimeout(id);
      }
    };
  }, [expression]);
};
