import * as React from 'react';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { useTranslation } from '../../../utils/useTranslation';
import { get } from 'lodash';
import {
  CreditProductT,
  DEFAULT_AMOUNT_STEP,
  DEFAULT_PERIOD_STEP,
  ProcessType,
  SettingsType,
} from '@brainysoft/lk-components';
import { useLock } from '../../../queries/useLock';
import { Formik, FormikProps } from 'formik';
import { FormikForm } from '../../../packages/Formik/Components/FormikForm';
import { Col, Row } from 'antd';
import { Slider } from '../../../packages/Formik/Components/Slider';
import { CreditProducts } from '../../../components/CreditProducts';
import { Button } from '../../../components/Button';
import { InterfaceModuleSettingT } from '../../../utils/useInterfaceModuleSetting';
import { Message } from '../../../components/Message';
import { useSetting } from '../../../utils/useSetting';
import { LoanAppAmountThresholdWarning } from './LoanAppAmountThresholdWarning';
import { useInitLoanAppProcess } from '../../../utils/useInitLoanAppProcess';

export interface ILoanAppCreateWidget {
  widgetSettings: InterfaceModuleSettingT;
  creditProducts: CreditProductT[];
}

export const LoanAppCreateWidget: React.FC<ILoanAppCreateWidget> = (props) => {
  const classes = useThemeStyles('LoanAppCreateWidget');

  const { widgetSettings: loanAppWidgetSettings, creditProducts } = props;
  const warningThreshold = useSetting(SettingsType.SELFIE_REQUEST_AMOUNT_THRESHOLD);

  const { t } = useTranslation();

  // const leadSettings = props.leadSettings;
  const initialCreditProduct = get(creditProducts, '0', {}) as any;
  const initialCreditProductId = initialCreditProduct.id;
  const initialIsShortTerm = initialCreditProduct?.options?.isShortTermCreditProduct;

  const [isButtonLoading, setIsButtonLoading] = React.useState(false);

  const [selectedCreditProduct, setSelectedCreditProduct] = React.useState(initialCreditProduct);
  const [periodLabel, setPeriodLabel] = React.useState('Срок кредита');
  const [amountStep, setAmountStep] = React.useState(initialCreditProduct.defaultStepAmount ?? DEFAULT_AMOUNT_STEP);
  const [periodStep, setPeriodStep] = React.useState(initialCreditProduct.defaultStepPeriod ?? DEFAULT_PERIOD_STEP);
  const [overThreshold, setOverThreshold] = React.useState<boolean>(
    warningThreshold && initialCreditProduct.defaultAmount > warningThreshold
  );
  const [isShortTerm, setIsShortTerm] = React.useState<boolean>(!!initialIsShortTerm);

  const displayAmountSlider = loanAppWidgetSettings?.showAmountSlider === false ? { display: 'none' } : undefined;
  const displayPeriodSlider =
    loanAppWidgetSettings?.showPeriodSlider === false || isShortTerm ? { display: 'none' } : undefined;

  const { data: lock, isLoading, isFetching } = useLock(ProcessType.LOAN_APP);

  const initialAmount =
    initialCreditProduct.defaultAmount ??
    Math.floor((initialCreditProduct.maxLoanAmount - initialCreditProduct.minLoanAmount) / 2000) * 1000 +
      initialCreditProduct.minLoanAmount;
  const initialPeriod =
    initialCreditProduct.defaultPeriod ??
    Math.floor((initialCreditProduct.maxPeriod - initialCreditProduct.minPeriod) / 2) + initialCreditProduct.minPeriod;

  const initProcess = useInitLoanAppProcess();

  const onSubmit = async (values) => {
    setIsButtonLoading(true);
    await initProcess(values, selectedCreditProduct);
  };

  const onUpdate = (formik) => {
    const creditProductId = get(formik, 'values.creditProductId');
    const amount = get(formik, 'values.amount');
    const period = get(formik, 'values.period');

    const creditProduct = get(
      creditProducts.filter((item) => item.id == creditProductId),
      0
    );
    if (creditProduct) {
      setSelectedCreditProduct(creditProduct);
      const label = creditProduct.periodInTranches
        ? `Количество выплат (общий срок кредита: ${period * creditProduct.trancheDuration} дней)`
        : 'Срок кредита, дней';
      setPeriodLabel(label);
      setAmountStep(creditProduct.defaultStepAmount ?? DEFAULT_AMOUNT_STEP);
      setPeriodStep(creditProduct.defaultStepPeriod ?? DEFAULT_PERIOD_STEP);
      setIsShortTerm(!!creditProduct?.options?.isShortTermCreditProduct);
    }
    setOverThreshold(warningThreshold && amount > warningThreshold);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          amount: initialAmount,
          period: initialPeriod,
          creditProductId: initialCreditProductId,
        }}
        onSubmit={onSubmit}
      >
        {(formik: FormikProps<any>) => {
          return (
            <FormikForm formik={formik} onSubmit={formik.handleSubmit} onUpdate={onUpdate}>
              <Row justify='center' gutter={24} className={classes.slidersWrapper}>
                <Col xs={24} sm={12} lg={24} style={displayAmountSlider}>
                  <div className='field'>
                    <Slider
                      label={t('widgets:loanAppCreate.amountLabel')}
                      name='amount'
                      defaultValue={initialAmount}
                      min={selectedCreditProduct.minLoanAmount}
                      max={selectedCreditProduct.maxLoanAmount}
                      step={amountStep}
                      className={overThreshold && !isShortTerm ? 'orange' : ''}
                    />
                  </div>
                  {overThreshold && !isShortTerm && <LoanAppAmountThresholdWarning threshold={warningThreshold} />}
                  {isShortTerm && (
                    <Message type={'info'} size={'small'} className={'text-center ml1 mr1'} style={{ width: 'auto' }}>
                      <div>
                        Доступный срок займа: <strong>7 дней.</strong>
                      </div>
                      <div>Для увеличения срока вам будет доступна функция оформления пролонгации</div>
                    </Message>
                  )}
                </Col>
                <Col xs={24} sm={12} lg={24} style={displayPeriodSlider}>
                  <div className='field'>
                    <Slider
                      label={periodLabel}
                      name='period'
                      defaultValue={initialPeriod}
                      min={selectedCreditProduct.minPeriod}
                      max={selectedCreditProduct.maxPeriod}
                      step={periodStep}
                    />
                  </div>
                </Col>
              </Row>
              {loanAppWidgetSettings?.showCreditProductSwitch && (
                <div className={classes.creditProductsSwitchWrapper}>
                  <CreditProducts
                    showIfSingle={loanAppWidgetSettings?.showIfSingle as boolean}
                    active={get(formik, 'values.creditProductId')}
                    creditProducts={creditProducts}
                    onChange={(id) => {
                      formik.setFieldValue('creditProductId', id);
                    }}
                    inWidget={true}
                  />
                </div>
              )}
              {lock?.isLocked && (
                <div className={classes.lockDisclaimer}>Создание заявки временно невозможно, попробуйте позднее</div>
              )}
              <div className={`${classes.formButtonWrapper} ${classes.mobileButtonWrapper}`}>
                <Button
                  loading={isButtonLoading}
                  type={'primary'}
                  htmlType={'submit'}
                  disabled={isLoading || isFetching || lock?.isLocked}
                >
                  {t('widgets:loanAppCreate.button')}
                </Button>
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};
