import { merge } from 'lodash';
import { THEME_COLORS } from '@custom/monedo/colors';

import { css as original } from '@custom/defaults/theme/Components/Card/styles';

const { primary, secondary, white } = THEME_COLORS;

export const css = merge(original, {
  card: {
    width: '300px',
    height: '190px',
    '&.placeholder': {
      width: '180px',
      height: '114px',
    },
    '& .addCardWrapper': {
      fontSize: '.857rem',
      textAlign: 'center',
    },
    '& .addCardPlaceholder': {
      marginBottom: '.5rem',
      '& .anticon': {
        fontSize: '1.5rem',
      },
    },
    '&.selected': {
      background: `linear-gradient(160deg, ${primary} 0%, ${secondary} 100%) !important`,
      boxShadow: '0 0 20px rgba(0,0,0,0.075)',
      color: white,
    },
  },
  cardNumber: {
    fontSize: '1.375rem',
    letterSpacing: 2.25,
  },
});

export default css;
