import * as React from 'react';
import { SurveyFormProps } from '../../../components/Surveys/SurveyForm';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { Input } from '../../../packages/Formik/Components/Input';
import { Select } from '../../../packages/Formik/Components/Select';
import { SurveyAnswerT } from '@brainysoft/lk-components';
import { Checkbox } from '../../../packages/Formik/Components/CheckBox';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { get } from 'lodash';

export const SurveyFormSmokers: React.FC<SurveyFormProps> = (props) => {
  const classes = useThemeStyles('Survey');
  const checkboxClasses = useThemeStyles('Form/CheckBox');

  const { survey, slot } = props;

  const formik = useFormikContext();

  const surveyConsentFieldName = `surveys.${survey.name}.consent`;
  const surveyConsentValue = get(formik.values, surveyConsentFieldName, undefined);

  useEffect(() => {
    if (surveyConsentValue === undefined) {
      formik.setFieldValue(surveyConsentFieldName, true);
    }
  }, [surveyConsentValue]);

  const defaultAnswers: SurveyAnswerT[] = [
    {
      key: 'no',
      order: 1,
      value: 'Нет',
    },
    {
      key: 'yes',
      order: 0,
      value: 'Да',
    },
  ];

  const answers = survey.answers || defaultAnswers;
  const answerOptions = answers
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .map((answer) => {
      return {
        option: answer.value,
        value: answer.key,
      };
    });

  return (
    <div className={classes.surveyWrapper}>
      <h4>{survey.question}</h4>
      <Input name={`surveys.${survey.name}.id`} initialValue={survey.id} hidden />
      <Input name={`surveys.${survey.name}.slot`} initialValue={slot} hidden />
      <Select
        name={`surveys.${survey.name}.answer`}
        options={answerOptions}
        placeholder={'Ответ никак не влияет на одобрение'}
      />

      <div className={checkboxClasses.checkboxWrapper}>
        <Checkbox name={`surveys.${survey.name}.consent`}></Checkbox>
        <div className={checkboxClasses.checkboxLabel}>
          <a
            href={'https://kredito24.ru/PMI/Soglashenie_o_predostavlenii_informatsii_pm.pdf'}
            target={'_blank'}
            rel='noopener noreferrer'
          >
            Согласен на получение информации
          </a>
          <span>и</span>
          <a
            href={'https://kredito24.ru/PMI/Politika_v_oblasti_obrabotki_personalnyh_dannyh_pm.pdf'}
            target={'_blank'}
            rel='noopener noreferrer'
          >
            даю согласие на обработку персональных данных
          </a>
          <span>ООО ФМСМ</span>
        </div>
      </div>
    </div>
  );
};
