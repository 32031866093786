import { FC, PropsWithChildren, useEffect } from 'react';
import { useCanCreate } from '../../../queries/useCanCreate';
import { useActiveCredit } from '../../../queries/useActiveCredit';
import { CreditStatusType, CreditT } from '@brainysoft/lk-components';
import { useDelay } from '../../../utils/useDelay';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';

const DENIED_SHOWCASE_URL = 'https://shortio.shortcm.li/6D66IW';
const DENIED_SHOWCASE_DELAY = 10 * 60 * 1000;

export const AuthorizedContentWrapperComponent: FC<PropsWithChildren<any>> = (props) => {
  const router = useRouter();

  const canCreateQuery = useCanCreate();
  const canCreate = canCreateQuery.data?.canCreate;

  const activeCreditQuery = useActiveCredit();
  const credit = activeCreditQuery.data || ({} as CreditT);
  const isDenied = credit.status == CreditStatusType.DENIED;

  //открытие с таймаутом в новом окне предложения для клиентов с отказом по лиду
  const urlOpen = () => {
    const a = document.createElement('a');
    a.setAttribute('href', DENIED_SHOWCASE_URL);
    a.setAttribute('target', '_blank');
    a.click();
  };

  useDelay(urlOpen, DENIED_SHOWCASE_DELAY, !canCreate && isDenied);

  // редирект на страницу rejected в течение суток после получения отказа
  // дата проставления статуса = дата создания лида = creationDate кредита
  // (именно так сейчас работают расчеты в consumer'е)

  const oneDayAfterDeny = dayjs(credit.creationDate).add(1, 'day');
  const isOneDayAfterDeny = dayjs().isBefore(oneDayAfterDeny);

  const isLoading = canCreateQuery.isLoading || activeCreditQuery.isLoading;

  useEffect(() => {
    if (isDenied && !isLoading && isOneDayAfterDeny) router.push('/rejected');
  }, [isLoading, isOneDayAfterDeny, isDenied]);

  return <>{props.children}</>;
};
