const colors = require('./theme_colors');

module.exports = {
  logo: 'https://cdn.brainysoft.ru/lk/tenants/a1403a91-78ee-4ecf-9cfd-d70986b5c502/logo-kredito24.svg',
  logoInverted: 'https://cdn.brainysoft.ru/lk/tenants/a1403a91-78ee-4ecf-9cfd-d70986b5c502/logo-kredito24.svg',
  logoAuth: 'https://cdn.brainysoft.ru/lk/tenants/a1403a91-78ee-4ecf-9cfd-d70986b5c502/logo-kredito24.svg',
  logoRegistration: 'https://cdn.brainysoft.ru/lk/tenants/a1403a91-78ee-4ecf-9cfd-d70986b5c502/logo-kredito24.svg',
  logoFooter: 'https://cdn.brainysoft.ru/lk/tenants/a1403a91-78ee-4ecf-9cfd-d70986b5c502/logo-kredito24.svg',
  authBackground: 'https://cdn.brainysoft.ru/lk/tenants/a1403a91-78ee-4ecf-9cfd-d70986b5c502/background.jpg',
  maxWidth: '1600px',
  fontFamily:
    '"Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  topBarHeight: '92px',
  topBarHeightMobile: '70px',
  generalBorder: `1px solid ${colors.greyLight}`,
  generalBorderLight: `1px solid ${colors.greyLighter}`,
  generalBorderRadius: '6px',
  generalBorderRadiusLarge: '9px',
  generalTransition: 'all 0.3s ease',
  generalTableBorderRadius: '0.5rem',
  loaderBarColor: colors.primary,
  fontSizeBase: '14px',
  fontSizeLarge: '16px',
  fontSizeSmall: '13px',
  inputBorderColor: colors.primary,
};
