import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { merge } from 'lodash';
import { css as original } from '@custom/defaults/theme/Components/ActiveCreditPage/styles';

export const css = merge(original, {
  signOfferWidget: {
    border: '2px solid #00b500',
    borderRadius: '16px',
    padding: '1rem',
    cursor: 'pointer',
    background: '#00b50021',
  },
  header: {
    marginBottom: 0,
  },
  subheader: {
    margin: 0,
  },
  insuranceInfoButton: {
    marginLeft: '.5rem !important',
  },
  insuranceDocument: {
    '& [class*="documentLink"]': {
      paddingRight: '0 !important',
    },
  },

  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
});

export default css;
