import * as React from 'react';
import { useThemeStyles } from '../../../utils/useThemeStyles';
import { Col, Divider, Row } from 'antd';
import { Currency } from '../../../components/Currency';
import { isEmpty } from 'lodash';
import { useProlongationSettings } from '../../../queries/useProlongationSettings';
import { Message } from '../../../components/Message';
import { useLegalEntityInfo } from '../../../queries/useLegalEntityInfo';
import { phoneParsed } from '../../../helpers';
import { ContractT, SettingsType, PrintFormContextType, PrintFormType } from '@brainysoft/lk-components';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { useTranslation } from '../../../utils/useTranslation';
import { IStatefulProcessRenderer } from '../../../components/StatefulProcess/AStatefulProcess';
import Decimal from 'decimal.js-light';
import { StatefulProcessActions } from '../../../components/StatefulProcess/StatefulProcessActions';
import { useSetting } from '../../../utils/useSetting';
import { PrintFormDocument } from '../../../components/PrintFormDocument/PrintFormDocument';
import { useClient } from '../../../queries/useClient';
import { useRouter } from 'next/router';
import dayjs, { Dayjs } from 'dayjs';

export const ProlongationChooseAmount: React.FC<IStatefulProcessRenderer> = (props) => {
  const { t } = useTranslation();
  const clientQuery = useClient();
  const classes = useThemeStyles('RepaymentPage');
  const router = useRouter();
  const { contract, state, goForward, isMutating } = props;

  const [selected, setSelected] = React.useState<any>({});
  const [buttonsDisabled, setButtonsDisabled] = React.useState(false);
  const prolongationQuery = useProlongationSettings(contract.id);
  const marketingActions = useSetting(SettingsType.MARKETING_ACTIONS);

  const legalEntityQuery = useLegalEntityInfo();
  const telephone = {
    formatted: phoneParsed(legalEntityQuery?.data?.telephone),
    raw: legalEntityQuery.data?.telephone ?? '',
  };

  const clickNext = () => {
    goForward({
      period: selected.period,
      ofertaCode: '0000' /* добавил код подписания для ланистера, т.к. оригинальный код убрали*/,
      amount: selected.amount ? new Decimal(selected.amount).toDecimalPlaces(2).toNumber() : undefined,
      // prolongationRepaymentDate: selected.prolongationRepaymentDate,
    });
  };

  let amount,
    showPeriods = false,
    hideAmount,
    finishDate;

  prolongationQuery.data?.forEach((item, index) => {
    hideAmount = index === 0 ? true : hideAmount && item.amount === amount;
    amount = item.amount;
    showPeriods = showPeriods || Boolean(item.amount);
    finishDate = finishDate || dayjs(item?.dateRange?.end).format('DD.MM.YYYY');
  });

  if (marketingActions?.prolongation?.active) hideAmount = false;

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <h2 className={classes.header}>{t('prolongation:changePeriod')}</h2>
        {!(prolongationQuery.isLoading || prolongationQuery.isFetching) && showPeriods && (
          <React.Fragment>
            <Row gutter={24} justify='center' className={classes.prolongationTermsWrapper}>
              {prolongationQuery.data?.map((prolongation, index) => (
                <Col xs={24} md={8} key={index}>
                  <ProlongationTerm
                    isSelected={selected?.index === index}
                    index={index}
                    contract={contract}
                    period={prolongation.days}
                    amount={prolongation.amount}
                    hideAmount={hideAmount}
                    setSelected={setSelected}
                  />
                </Col>
              ))}
            </Row>
            {hideAmount && (
              <div>
                {t('prolongation:disclaimer')}{' '}
                <b>
                  <Currency className={classes.price} amount={amount} ratio={0.85} />
                </b>
              </div>
            )}
            {hideAmount && contract?.isDelinquency && finishDate && (
              <Row gutter={24} justify='center' className={classes.prolongationTermsWrapper}>
                <Col xs={24} md={prolongationQuery.data?.length ? prolongationQuery.data?.length * 6 : 18}>
                  <Message type={'info'}>
                    {t('prolongation:fixedAmountSize')} <Currency amount={amount} ratio={0.8} />
                    <strong>*</strong>
                    {t('prolongation:marketingInfo', { finishDate })}.
                  </Message>
                </Col>
              </Row>
            )}
            <h4>
              * Указанная сумма будет направлена на погашение ранее начисленных процентов в соответствии с условиями
              договора микрозайма
            </h4>
          </React.Fragment>
        )}
        {!(prolongationQuery.isLoading || prolongationQuery.isFetching) && !showPeriods && (
          <Message type={'error'}>
            {t('prolongation:message.error')} <br /> {t('prolongation:message.errorAdvice')}{' '}
            <a href={`tel:${telephone.raw}`}>{telephone.formatted}</a>
          </Message>
        )}
        {(prolongationQuery.isLoading || prolongationQuery.isFetching) && <LoadingSpinner size={8} margin={4} />}
      </div>

      <div style={{ fontSize: '1.0rem', margin: '1rem' }}>
        Нажимая кнопку Оплатить, вы подтверждаете, что ознакомились и согласились с условиями&nbsp;
        {selected?.period && selected?.amount && (
          <PrintFormDocument
            name={t(`printForms:name.${PrintFormType.PROLONGATION}`)}
            type={'link'}
            params={{
              clientId: clientQuery.data?.id,
              leadId: contract?.leadId,
              loanAppId: contract?.loanApplicationId,
              contractId: contract?.id,
              variables: {
                prolongationPeriod: selected?.period,
                amount: selected?.amount,
              },
            }}
            contextType={PrintFormContextType.RAW}
            printFormType={PrintFormType.PROLONGATION}
          />
        )}
        {(!selected?.period || !selected?.amount) && (
          <span style={{ opacity: '0.5' }}>&nbsp;{t(`printForms:name.${PrintFormType.PROLONGATION}`)}</span>
        )}
      </div>

      <Divider />

      <StatefulProcessActions
        showForward={state.transitions?.forward}
        showBack={true}
        onClickForward={clickNext}
        onClickBack={() => {
          if (contract?.id) router.push({ pathname: `/contracts/${contract.id}` });
        }}
        textForward={t('prolongation:pay')}
        isForwardDisabled={isEmpty(selected) || buttonsDisabled}
        isMutating={isMutating}
        buttonsFullWidth={true}
      />
      <p className={classes.disclaimer}>
        По одному договору займа можно оформить не более пяти продлений. После пятого продления нужно погасить заём
        полностью - и при необходимости можно сразу оформить новый.
      </p>
    </React.Fragment>
  );
};

interface IProlongationTerm {
  index: number;
  contract: ContractT;
  period: number;
  amount: number;
  hideAmount?: boolean;
  setSelected: (arg: { index: number; period: number; amount: number; prolongationRepaymentDate: string }) => void;
  isSelected: boolean;
}

const ProlongationTerm: React.FC<IProlongationTerm> = (props) => {
  const classes = useThemeStyles('RepaymentPage');
  const { t } = useTranslation();

  const { index, period, amount, hideAmount, isSelected, setSelected } = props;

  const newPaymentDate = dayjs().add(period, 'days');

  const onClick = () => {
    setSelected({
      index: index,
      period: period,
      prolongationRepaymentDate: newPaymentDate.format('YYYY-MM-DD'),
      amount: amount,
    });
  };

  return (
    <div className={`${classes.prolongationTermWrapper} ${isSelected ? 'selected' : ''}`} onClick={onClick}>
      <div className={classes.prolongationTerms}>
        <span className={classes.prolongationTermsPeriod}>{period}</span>
        {t('prolongation:prolongationTermsPeriod.days')} <br />
        {!hideAmount && (
          <React.Fragment>
            {t('prolongation:prolongationTermsPeriod.per')}
            <Currency className={classes.price} amount={amount} ratio={0.85} />
            <strong> * </strong>
          </React.Fragment>
        )}
      </div>
      <div className={classes.prolongationNewPeriod}>
        {t('prolongation:prolongationNewPeriod')}: {newPaymentDate.format('DD.MM.YYYY')}
      </div>
    </div>
  );
};
