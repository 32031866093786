import { SCREEN_SIZE } from '@brainysoft/lk-components';

import { merge } from 'lodash';

import { css as original } from '@custom/defaults/theme/Components/RepaymentWidget/styles';

export const css = merge(original, {
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '.5rem',
  },
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
});

export default css;
