// export {css as InputNumber} from './InputNumber/styles';
export { css as Slider } from './Slider/styles';
export { css as PhoneInputApprove } from './PhoneInputApprove/styles';

export {
  CheckBox,
  DadataInputAntd,
  DatePicker,
  Dragger,
  FormikForm,
  FormItem,
  Input,
  InputNumber,
  MaskedInput,
  PhoneInput,
  // PhoneInputApprove,
  Select,
  // Slider,
  Upload,
  Error,
} from '@custom/defaults/theme/Form';
