import { merge } from 'lodash';
import { SCREEN_SIZE } from '@brainysoft/lk-components';

import { css as original } from '@custom/defaults/theme/Components/Header/styles';

export const css = merge(original, {
  phoneMenuWrapper: {
    flexBasis: '82%',
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
});

export default css;
