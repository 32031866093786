import * as React from 'react';
import { Message } from '../../../components/Message';

export const LoanAppAmountThresholdWarning: React.FC<{ threshold: number }> = (props) => {
  const { threshold } = props;

  return (
    <Message type={'warning'} size={'small'} className={'text-center ml1 mr1'} style={{ width: 'auto' }}>
      Сумма больше {threshold} руб. доступна лояльным клиентам
    </Message>
  );
};
