import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { merge } from 'lodash';
import { css as original } from '@custom/defaults/theme/Components/AuthPage/styles';
import { authBackground } from '@custom/monedo/variables';

export const css = merge(original, {
  pageTitle: {
    fontSize: '1.5rem',
  },
  authBackgroundImage: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: '50%',
    background: `url(${authBackground}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '25% center',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      // background: 'linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%)',
      background: 'linear-gradient(0deg, rgba(0,0,0,0) 70%, rgba(0,0,0,0.2) 100%)',
    },
  },
  [`@media (max-width: ${SCREEN_SIZE.TABLET}px)`]: {},
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {},
});

export default css;
