import * as React from 'react';
import { ResultIcon } from '../../../../components/ResultIcon';
import { ResultType } from '@brainysoft/lk-components';
import Link from 'next/link';
import { useThemeStyles } from '../../../../utils/useThemeStyles';
import { SettingsType } from '@brainysoft/lk-components';
import { useSetting } from '../../../../utils/useSetting';
import { useTranslation } from '../../../../utils/useTranslation';
import { IActiveCreditStatus } from '../../../../components/ActiveCreditWidget/ActiveCreditSelector';

const interactionInitUrl = '/contracts/sign/init';

export const InteractionSignOffer: React.FC<IActiveCreditStatus> = () => {
  const classes = useThemeStyles('ActiveCreditPage');
  const { t } = useTranslation();

  const iconSize = 60;
  const signOfferLimit = useSetting(SettingsType.SIGN_OFFER_LIMIT);

  return (
    <React.Fragment>
      <Link href={interactionInitUrl} passHref>
        <div className={`${classes.wrapper} ${classes.signOfferWidget}`}>
          <h2 className={classes.header}>{t('active:statuses.signOffer.header')}</h2>
          {signOfferLimit && <div>{t('active:statuses.signOffer.headerSignature', { days: signOfferLimit })}</div>}
          <ResultIcon result={ResultType.SUCCESS} size={iconSize} />
          <h3 className={classes.subheader}>{t('active:statuses.signOffer.more')}</h3>
        </div>
      </Link>
    </React.Fragment>
  );
};
