import { merge } from 'lodash';
import { colors } from '@custom/monedo/theme/Styles';

import { css as original } from '@custom/defaults/theme/Components/LoanAppCreatePage/styles';

const { primary, orange2 } = colors;

export const css = merge(original, {
  warning: {
    fontWeight: 'normal',
    color: primary,
    textAlign: 'center',
  },
});

export default css;
