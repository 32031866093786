import { merge } from 'lodash';

import { SCREEN_SIZE } from '@brainysoft/lk-components';
import { css as original } from '@custom/defaults/theme/Form/PhoneInputApprove/styles';

export const css = merge(original, {
  [`@media (max-width: ${SCREEN_SIZE.MOBILE}px)`]: {
    approveWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      textAlign: 'left',
    },
    inputWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > .field-wrapper': {
        width: '100%',
      },
    },
  },
});

export default css;
