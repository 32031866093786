const Color = require('color');

const primary = '#195491';
const secondary = '#0397D6';
const grey = 'rgb(179,180,181)';

const primary2 = '#0397D6';
const primary3 = '#89CAEA';

const mineShaft = '#1D2B3F';
const mineShaft2 = '#394B67';
const mineShaft3 = '#51637F';

const orange = '#F7A72F';
const orange2 = '#EB8F03';
const orange3 = '#FFC368';

module.exports = {
  white: 'rgb(255,255,255)',
  black: 'rgb(0,0,0)',
  primary: primary,
  primaryDarken: Color(primary).darken(0.2).rgb().string(),
  primaryLight: Color(primary).lighten(0.2).rgb().string(),
  primaryDark: Color(primary).darken(0.9).rgb().string(),
  primaryBackground: Color(primary).lighten(0.96).rgb().string(),
  grey: 'rgb(179,180,181)',
  greyDark: Color(grey).darken(0.9).rgb().string(),
  greyLight: Color(grey).lighten(0.2).rgb().string(),
  greyLighter: Color(grey).lighten(0.3).rgb().string(),
  greyText: 'rgb(59,60,61)',
  greyLightText: 'rgb(179,180,181)',
  red: 'rgb(240,0,0)',
  blue: 'rgb(0,160,220)',
  green: 'rgb(0,190,40)',
  yellow: 'rgb(250,150,0)',
  pageBackground: 'rgb(242,243,244)',
  primaryHover: 'rgb(248,252,255)',

  secondary: secondary,
  secondaryBackground: Color(secondary).lighten(0.96).rgb().string(),

  highlightColor: 'rgb(245,20,20)',
  disabledBackground: 'rgb(245,245,245)',
  borderColor: 'rgb(220,220,222)',

  orange: orange,
  primary2: primary2,
  orange2: orange2,
  orange3: orange3,
};
