import { merge } from 'lodash';

import { css as original } from '@custom/defaults/theme/Components/RegistrationPage/styles';

export const css = merge(original, {
  acceptAllWrapper: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& > [class^="checkboxWrapper"]': {
      marginBottom: 0,
    },
  },
  acceptAllArrow: {
    marginLeft: '.25rem',
    transition: 'all .2s ease',
    '&.active': {
      transform: 'rotate(90deg)',
    },
  },
  textTemplate1: {
    fontSize: '.875rem',
    color: 'rgba(0, 0, 0, 0.85)',
  },
  codeApprovalWrapper: {
    '& [class^="disclaimer"]': {
      color: 'rgba(0, 0, 0, 0.85)',
      fontSize: '.875rem',
    },
  },
  grayStyleCheckbox: {
    '& .ant-checkbox-checked .ant-checkbox-inner': {
      backgroundColor: 'rgb(179,180,181)',
      borderColor: 'rgb(179,180,181)',
    },
  },
});

export default css;
