import { merge } from 'lodash';

import { css as original } from '@custom/defaults/theme/Form/Slider/styles';

import { orange } from '../../Styles/theme_colors';

export const css = merge(original, {
  slider: {
    '&.orange .ant-slider-track': {
      height: '6px',
      backgroundColor: orange,
    },
    '&.orange.ant-slider .ant-slider-handle': {
      width: '20px',
      height: '20px',
      marginTop: '-4px',
      borderColor: `${orange} !important`,
      '&:after': {
        boxShadow: `0 0 0 2px ${orange} !important`,
      },
    },
    '&.orange:hover': {
      '& .ant-slider-track': {
        backgroundColor: `${orange} !important`,
      },
      '&.ant-slider .ant-slider-handle': {
        '&:after': {
          boxShadow: `0 0 0 4px ${orange} !important`,
        },
      },
    },
  },
  numberValue: {
    '&.orange': {
      color: orange,
    },
  },
  numberValueUnit: {
    '&.orange': {
      color: orange,
    },
  },
  numberInput: {
    '&.orange input': {
      color: `${orange} !important`,
    },
  },
});

export default css;
